@charset "UTF-8";
@font-face {
  font-family: 'Notification';
  src: url(/static/media/notification.c0d3c94c.eot);
  src: url(/static/media/notification.c0d3c94c.eot?#iefixs3g3t9) format("embedded-opentype"), url(/static/media/notification.651771e1.woff) format("woff"), url(/static/media/notification.0b4ac1dc.ttf) format("truetype"), url(/static/media/notification.5bee74ca.svg#notification) format("svg");
  font-weight: normal;
  font-style: normal;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  color: #fff;
  background-color: #ccc;
  box-shadow: 0 0 12px #999;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover, .notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  font-family: 'Notification';
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}

.notification-info {
  background-color: #2f96b4;
}

.notification-info:before {
  content: "\F05A";
}

.notification-success {
  background-color: #51a351;
}

.notification-success:before {
  content: "\F058";
}

.notification-warning {
  background-color: #f89406;
}

.notification-warning:before {
  content: "\F06A";
}

.notification-error {
  background-color: #bd362f;
}

.notification-error:before {
  content: "\F057";
}

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}
button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled].rbc-btn {
  cursor: not-allowed;
}
button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}
.rbc-abs-full,
.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rbc-rtl {
  direction: rtl;
}
.rbc-off-range {
  color: #999999;
}
.rbc-off-range-bg {
  background: #e5e5e5;
}
.rbc-header {
  overflow: hidden;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #DDD;
}
.rbc-header + .rbc-header {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-content {
  position: relative;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}
.rbc-today {
  background-color: #eaf6ff;
}
.rbc-toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
.rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: .375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
  margin-left: 10px;
}
.rbc-event {
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.rbc-slot-selecting .rbc-event {
  cursor: inherit;
  pointer-events: none;
}
.rbc-event.rbc-selected {
  background-color: #265985;
}
.rbc-event-label {
  font-size: 80%;
}
.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}
.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-day-after {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-day-prior {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-row {
  display: flex;
  flex-direction: row;
}
.rbc-row-segment {
  padding: 0 1px 1px 1px;
}
.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}
.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  white-space: nowrap;
}
.rbc-month-view {
  position: relative;
  border: 1px solid #DDD;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  height: 100%;
}
.rbc-month-header {
  display: flex;
  flex-direction: row;
}
.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #DDD;
}
.rbc-date-cell {
  flex: 1 1;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0;
  overflow: hidden;
}
.rbc-day-bg {
  flex: 1 0;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.rbc-overlay > * + * {
  margin-top: 1px;
}
.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}
.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: auto;
}
.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid #DDD;
  border-spacing: 0;
  border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #DDD;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #DDD;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}
.rbc-agenda-time-cell {
  text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
  content: ' \BB';
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: '\AB   ';
}
.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}
.rbc-agenda-event-cell {
  width: 100%;
}
.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
  flex: 1 1;
}
.rbc-timeslot-group {
  border-bottom: 1px solid #DDD;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}
.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}
.rbc-label {
  padding: 0 5px;
}
.rbc-day-slot {
  position: relative;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 10px;
  top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-is-rtl {
  left: 10px;
  right: 0;
}
.rbc-day-slot .rbc-event {
  border: 1px solid #265985;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}
.rbc-time-slot {
  flex: 1 0;
}
.rbc-time-slot.rbc-now {
  font-weight: bold;
}
.rbc-day-header {
  text-align: center;
}
.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}
.rbc-slot-selecting {
  cursor: move;
}
.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  border: 1px solid #DDD;
  min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}
.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  position: relative;
}
.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}
.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #DDD;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #DDD;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #DDD;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #DDD;
}
.rbc-time-header-content {
  flex: 1 1;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-time-content {
  display: flex;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #DDD;
  overflow-y: auto;
  position: relative;
}
.rbc-time-content > .rbc-time-gutter {
  flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}
.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.show-loading-animation.rect-shape, .show-loading-animation.round-shape, .show-loading-animation.text-row,
.show-loading-animation .rect-shape,
.show-loading-animation .round-shape,
.show-loading-animation .text-row {
  -webkit-animation: react-placeholder-pulse 1.5s infinite;
          animation: react-placeholder-pulse 1.5s infinite; }

@-webkit-keyframes react-placeholder-pulse {
  0% {
    opacity: .6; }
  50% {
    opacity: 1; }
  100% {
    opacity: .6; } }

@keyframes react-placeholder-pulse {
  0% {
    opacity: .6; }
  50% {
    opacity: 1; }
  100% {
    opacity: .6; } }

